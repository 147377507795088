import { EgretLoadable } from "egret";
import { authRoles } from "../../auth/authRoles";

const LearningManagement = EgretLoadable({
  loader: () => import("./LearningManagement")
});
const Analytics = EgretLoadable({
  loader: () => import("./Analytics")
});
const Sales = EgretLoadable({
  loader: () => import("./Sales")
});
const Dashboard1 = EgretLoadable({
  loader: () => import("./Dashboard1")
});
const Setup = EgretLoadable({
  loader: () => import("./Setup")
});
const Congratulation = EgretLoadable({
  loader: () => import("./Congratulation")
});

const TryIt = EgretLoadable({
  loader: () => import("./TryIt")
});

const Dashboard2 = EgretLoadable({
  loader: () => import("./Dashboard2")
});

const dashboardRoutes = [
  {
    path: "/dashboard/setup",
    component: Setup,
    auth: authRoles.admin
  },
  {
    path: "/dashboard/congratulation",
    component: Congratulation,
    auth: authRoles.admin
  },
  {
    path: "/dashboard/analytics",
    component: Analytics,
    auth: authRoles.admin
  },
  {
    path: "/dashboard/sales",
    component: Sales,
    auth: authRoles.admin
  },
  {
    path: "/dashboard/scoping",
    component: TryIt,
    auth: authRoles.admin
  },
  // {
  //   path: "/dashboard/dashboard1",
  //   component: Dashboard1
  // },
  {
    path: "/dashboard/learning-management",
    component: LearningManagement,
    auth: authRoles.admin
  },
  {
    path: "/dashboard/Dashboard2",
    component: Dashboard2,
    auth: authRoles.admin
  },

];

export default dashboardRoutes;
