import { EgretLoadable } from "egret";
import { authRoles } from "../../auth/authRoles";

const Users = EgretLoadable({
    loader: () => import("./Users.jsx")
});
const OrgChart = EgretLoadable({
    loader: () => import("./OrgChart")
});

// const Cart = EgretLoadable({
//     loader: () => import("./Cart")
// });

// const Checkout = EgretLoadable({
//     loader: () => import("./Checkout")
// });

const UserRoutes = [
    {
        path: "/users",
        component: Users,
        auth: authRoles.admin
    },
    {
        path: "/tree",
        component: OrgChart,
        auth: authRoles.admin
    },

];

export default UserRoutes;
