import {
    Button,

    CircularProgress,
    Typography, withStyles
} from "@material-ui/core";
import { PropTypes } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { loginWithEmailAndPassword } from "../../redux/actions/LoginActions";


const styles = theme => ({
    wrapper: {
        position: "relative"
    },

    buttonProgress: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    }
});

class SignInForm extends Component {
    state = {
        email: "watson@example.com",
        password: "testpass",
        agreement: ""
    };
    handleChange = event => {
        event.persist();
        this.setState({
            [event.target.name]: event.target.value
        });
    };
    handleFormSubmit = event => {
        event.preventDefault();
        this.props.loginWithEmailAndPassword({ ...this.state });
    };
    render() {
        let { email, password } = this.state;
        let { classes } = this.props;
        return (
            <div style={{ paddingTop: "40px" }}>

                <form ref="form" onSubmit={(event) => this.handleFormSubmit(event)}>
                    <input
                        className="mb-24 w-100 inputField p-12"
                        placeholder="Email"
                        onChange={this.handleChange}
                        type="email"
                        name="email"
                        value={email}
                        required
                    />
                    <input
                        className="mb-24 w-100 inputField p-12"
                        placeholder="Password"
                        onChange={this.handleChange}
                        type="password"
                        name="password"
                        value={password}
                        required
                    />
                    {/* <TextValidator
                        className="mb-16 w-100"
                        label="Password"
                        variant="outlined"
                        onChange={this.handleChange}
                        name="password"
                        type="password"
                        value={password}
                        validators={["required"]}
                        errorMessages={["this field is required"]}
                    /> */}
                    {/* <FormControlLabel
                        className="mb-8"
                        name="agreement"
                        onChange={this.handleChange}
                        control={<Checkbox checked />}
                        label="I have read and agree to the terms of service."
                    /> */}
                    <div className="flex flex-middle mb-8">
                        <div className={classes.wrapper + " w-100"}>
                            <Button
                                variant="contained"
                                color="secondary"
                                size="large"
                                disabled={this.props.login.loading}
                                type="submit"
                                fullWidth={true}
                            >
                                Login
                        </Button>
                            {this.props.login.loading && (
                                <CircularProgress
                                    size={24}
                                    className={classes.buttonProgress}
                                />
                            )}
                        </div>
                        {/* <span className="ml-16 mr-8">or</span> */}
                        {/* <Button
                            className="capitalize"
                            onClick={() =>
                                this.props.history.push("/session/signup")
                            }
                        >
                            Sign up
                      </Button> */}
                    </div>
                    <Button
                        className="text-primary"
                        fullWidth={true}
                        onClick={() =>
                            this.props.history.push("/session/forgot-password")
                        }
                    >
                        <Typography variant="caption" color="secondary">

                            Forgot password?
                    </Typography>
                    </Button>
                </form>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loginWithEmailAndPassword: PropTypes.func.isRequired,
    login: state.login
});
export default withStyles(styles, { withTheme: true })(
    withRouter(
        connect(
            mapStateToProps,
            { loginWithEmailAndPassword }
        )(SignInForm)
    )
);
