import { EgretLoadable } from "egret";
import { authRoles } from "../../auth/authRoles";

const Directory = EgretLoadable({
    loader: () => import("./Directory.jsx")
});

const DirectorySummary = EgretLoadable({
    loader: () => import("./Summary.jsx")
});


// const Cart = EgretLoadable({
//     loader: () => import("./Cart")
// });

// const Checkout = EgretLoadable({
//     loader: () => import("./Checkout")
// });

const DirectoryRoutes = [
    {
        path: "/directory/all",
        component: Directory,
        auth: authRoles.admin

    },
    {
        path: "/directory/:id",
        component: DirectorySummary,
        auth: authRoles.admin
    },
    // {
    //     path: "/my-campus/checkout",
    //     component: Checkout,
    //     auth: authRoles.admin
    // },
];

export default DirectoryRoutes;
