import {
  Button, Card,


  Grid, Typography, withStyles
} from "@material-ui/core";
import { PropTypes } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { loginWithEmailAndPassword } from "../../redux/actions/LoginActions";
import SignInForm from "./SignInForm";


const styles = theme => ({
  wrapper: {
    position: "relative"
  },

  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
});

class SignIn extends Component {
  state = {
    email: "watson@example.com",
    password: "testpass",
    agreement: ""
  };
  handleChange = event => {
    event.persist();
    this.setState({
      [event.target.name]: event.target.value
    });
  };
  handleFormSubmit = event => {
    this.props.loginWithEmailAndPassword({ ...this.state });
  };
  render() {
    let { email, password } = this.state;
    let { classes } = this.props;
    return (
      <div className="flex flex-center w-100 h-100vh">
        <div className="w-100 h-100vh" >
          <Card className="position-relative y-center h-100vh">
            <Grid container>
              <Grid container item lg={6} md={6} sm={12} justify="space-around" alignItems="center" className="h-100vh">
                <Grid item sm={8} style={{ maxWidth: "366px" }}>
                  <div className="m-10">
                    <img src="/assets/images/material/logoWithTitle.png" alt="logo-with-title"></img>
                    <SignInForm></SignInForm>
                    <Typography align="center">
                      Don't have an account,<Button color="primary" onClick={() => { this.props.history.push("/home") }}>SignUp</Button>
                    </Typography>
                  </div>
                </Grid>
              </Grid>
              <Grid item lg={6} md={6} sm={12} className="h-100vh disappearGirlImg">
                <div className=" flex flex-center flex-middle h-100">
                  <img src="/assets/images/material/loginPageGirl.jpg" alt="" height="100%" />
                </div>
              </Grid>
            </Grid>


          </Card>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loginWithEmailAndPassword: PropTypes.func.isRequired,
  login: state.login
});
export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(
      mapStateToProps,
      { loginWithEmailAndPassword }
    )(SignIn)
  )
);
