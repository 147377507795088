import { EgretLoadable } from "egret";
import { authRoles } from "../../auth/authRoles";

const MyCampusInfo = EgretLoadable({
    loader: () => import("./MyCampusInfo")
});

// const Cart = EgretLoadable({
//     loader: () => import("./Cart")
// });

// const Checkout = EgretLoadable({
//     loader: () => import("./Checkout")
// });

const MyCampusRoutes = [
    {
        path: "/myCampus/info",
        component: MyCampusInfo,
        auth: authRoles.admin
    },
    // {
    //     path: "/my-campus/cart",
    //     component: Cart,
    //     auth: authRoles.admin
    // },
    // {
    //     path: "/my-campus/checkout",
    //     component: Checkout,
    //     auth: authRoles.admin
    // },
];

export default MyCampusRoutes;
