import React, { Component } from "react";
import { withStyles, Icon, Avatar } from "@material-ui/core";
import TouchRipple from "@material-ui/core/ButtonBase";
import { withRouter, useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";


const styles = theme => {
  return {
    expandIcon: {
      transition: "transform 0.3s cubic-bezier(0, 0, 0.2, 1) 0ms",
      transform: "rotate(90deg)"
      // marginRight: "16px"
    },
    collapseIcon: {
      transition: "transform 0.3s cubic-bezier(0, 0, 0.2, 1) 0ms",
      transform: "rotate(0deg)",
      display: "none"
      // marginRight: "16px"
    },
    "expansion-panel": {
      overflow: "hidden",
      transition: "max-height 0.3s cubic-bezier(0, 0, 0.2, 1)"
    },
    highlight: {
      background: theme.palette.primary.main
    }
  };
};

class EgretVerticalNavExpansionPanel extends Component {
  state = {
    collapsed: true,
    isActive: ''
  };
  elementRef = React.createRef();

  componentHeight = 0;

  handleClick = () => {
    let path = this.props.history.location.pathname
    let { classes, children, item } = this.props;
    path = path.slice(0, path.lastIndexOf("/"))
    if (item.path === path) {
      this.setState({ ...this.state, isActive: 'active' });

    }
    this.setState({ ...this.state, collapsed: !this.state.collapsed });
  };

  calcaulateHeight(node) {
    if (node.name !== "child") {
      for (let child of node.children) {
        this.calcaulateHeight(child);
      }
    }
    this.componentHeight += node.clientHeight;
    return;
  }
  componentDidMount() {
    let { location } = this.props;
    this.calcaulateHeight(this.elementRef);

    // OPEN DROPDOWN IF CHILD IS ACTIVE
    for (let child of this.elementRef.children) {
      if (child.getAttribute("href") === location.pathname) {
        this.setState({ collapsed: false });
      }
    }
  }
  render() {
    let { collapsed } = this.state;
    let { classes, children, item } = this.props;
    let { name, icon, badge } = this.props.item;
    let path = this.props.history.location.pathname
    path = path.slice(0, path.lastIndexOf("/"))
    let isActive = "";
    if (item.path === path) {
      isActive = 'active'

    }



    return (
      <div>
        {/* <NavLink to={submenuItems[0].path} className="nav-item special"> */}
        <TouchRipple
          className={"nav-item flex-middle w-100 special " + this.state.isActive + " " + isActive}
          style={{ padding: "0px" }}
          onClick={this.handleClick}
        >
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}>
            <Avatar className="special MuiAvatar-colorDefault" >
              <Icon className="text-middle item-icon">{icon}</Icon>
            </Avatar>
            <span className="text-middle item-text">{name}</span>
          </div>
          {badge && (
            <div className={`badge bg-${badge.color}`}>{badge.value}</div>
          )}
          <div
            className={
              collapsed
                ? classes.collapseIcon + " item-arrow"
                : classes.expandIcon + " item-arrow"
            }
          >
            <Icon className="text-middle">chevron_right</Icon>
          </div>
        </TouchRipple>
        {/* </NavLink> */}
        <div
          ref={el => (this.elementRef = el)}
          className={classes["expansion-panel"] + " submenu"}
          style={
            collapsed
              ? { maxHeight: "0px" }
              : { maxHeight: this.componentHeight + "px" }
          }


        >
          <div
            onClick={this.handleClick}
          >


            {children}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(EgretVerticalNavExpansionPanel));
