import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Icon, Avatar } from "@material-ui/core";
import TouchRipple from "@material-ui/core/ButtonBase";
import EgretVerticalNavExpansionPanel from "./EgretVerticalNavExpansionPanel";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  expandIcon: {
    transition: "transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms",
    transform: "rotate(90deg)"
  },
  collapseIcon: {
    transition: "transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms",
    transform: "rotate(0deg)"
  }
});

class EgretVerticalNav extends Component {
  state = {
    collapsed: true,

  };

  renderLevels = data => {
    return data.map((item, index) => {
      if (item.children) {
        return (
          <EgretVerticalNavExpansionPanel item={item} key={index}>
            {this.renderLevels(item.children)}
          </EgretVerticalNavExpansionPanel>
        );
      } else {
        return (
          <NavLink key={index} to={item.path} className="nav-item special">
            <TouchRipple key={item.name} name="child" className="w-100" style={{ padding: "0px" }}>
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}>

                {(() => {
                  if (item.icon) {
                    return (
                      <div>
                        <Avatar className="special MuiAvatar-colorDefault">

                          <Icon className="item-icon text-middle">{item.icon}</Icon>
                        </Avatar>
                      </div>
                    );
                  } else {
                    return (
                      <div>
                        <Avatar className="special MuiAvatar-colorDefault">

                          <span className="item-icon icon-text text-middle">{item.iconText}</span>
                        </Avatar>
                      </div>
                    );
                  }
                })()}
                <div className="text-middle item-text">{item.name}</div>
                <div className="mx-auto"></div>
                {item.badge && (
                  <div className={`badge bg-${item.badge.color}`}>
                    {item.badge.value}
                  </div>
                )}
              </div>

            </TouchRipple>
          </NavLink>
        );
      }
    });
  };

  handleClick = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };

  render() {
    return (
      <div className="navigation">
        {this.renderLevels(this.props.navigation)}
      </div>
    );
  }
}

export default withStyles(styles)(EgretVerticalNav);
