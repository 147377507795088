// import "../fake-db";
import "../styles/_app.scss";
import React from "react";
import { Provider } from "react-redux";
import { Router, Switch, Route } from "react-router-dom";
import EgretTheme from "./EgretLayout/EgretTheme/EgretTheme";
import AppContext from "./appContext";
// import history from "history.js";

import routes from "./RootRoutes";
import { Store } from "./redux/Store";
import Auth from "./auth/Auth";
import EgretLayout from "./EgretLayout/EgretLayout";
import AuthGuard from "./auth/AuthGuard";
// import Home from "home/Home";

const JSXApp = () => (
  <AppContext.Provider value={{ routes }}>
    <Provider store={Store}>
      <EgretTheme>
        <Auth>
          <AuthGuard>
            <EgretLayout />
          </AuthGuard>
        </Auth>
      </EgretTheme>
    </Provider>
  </AppContext.Provider>
)
function App() {
  return (
    // <Router history={history}>

    // <Switch>
    //   <Route exact path="/" component={Home}></Route>
    //   <Route component={JSXApp}></Route>
    // </Switch>
    // </Router>
    <>
      <JSXApp />
    </>

  );
};

export default App;
