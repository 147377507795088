import { EgretLoadable } from "egret";
import { authRoles } from "../../auth/authRoles";

const FeeSummary = EgretLoadable({
  loader: () => import("./FeeSummary")
});




const feeRoutes = [
  {
    path: "/fee/feeSummary",
    component: FeeSummary,
    auth: authRoles.admin
  },


];

export default feeRoutes;
