// import SignUp from "./SignUp";
// import Home from "./Home";
// import SignIn from "./SignIn";
// import ForgotPassword from "./ForgotPassword";
import { EgretLoadable } from "egret";
const NotFound = EgretLoadable({ loader: () => import("./NotFound") });
const Registration = EgretLoadable({ loader: () => import("./Registration") });
const Login = EgretLoadable({ loader: () => import("./Login") });
const Congratulation = EgretLoadable({ loader: () => import("./Congratulation") });



const settings = {
  activeLayout: "layout1",
  layout1Settings: {
    topbar: {
      show: false
    },
    leftSidebar: {
      show: false,
      mode: "close"
    }
  },
  layout2Settings: {
    mode: "full",
    topbar: {
      show: false
    },
    navbar: { show: false }
  },
  secondarySidebar: { show: false },
  footer: { show: false }
};

const sessionRoutes = [
  {
    path: "/session/registration",
    component: Registration,
    settings
  },
  {
    path: "/session/login",
    component: Login,
    settings
  },
  {
    path: "/congratulation",
    component: Congratulation,
    settings
  },
  // {
  //   path: "/session/signup",
  //   component: SignUp,
  //   settings
  // },
  // {
  //   path: "/session/signin",
  //   component: SignIn,
  //   settings
  // },
  // {
  //   path: "/session/forgot-password",
  //   component: ForgotPassword,
  //   settings
  // },
  {
    path: "/session/404",
    component: NotFound,
    settings
  },
  // {
  //   path: "/home",
  //   component: Home,
  //   settings
  // }
];

export default sessionRoutes;
